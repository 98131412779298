<template>
  <div id="teacher-create-container" class="content-container">
    <PageTitle :title="$t('TEACHER_OVERVIEW.TEACHERS')" class="page-title" />

    <h1 class="page-section-title section-title">
      {{ $t('TEACHER_OVERVIEW.CREATE_TEACHER.CREATE_NEW_TEACHER') }}
    </h1>
    <BackButton
      :text="$t('BACK_OVERVIEW')"
      :route-name="ROUTE_NAMES_HELPDESK.TEACHERS_OVERVIEW"
    />

    <FormWrapper :send-form-data="validateData" class="page-form">

      <template #form-content>
        <!-- Name -->
        <div class="two-column-wrapper name-columns">

          <InputField
            :field-title="$t('FIRST_NAME')"
            :field-name="'firstName'"
            :rules="'required'"
            :type="'text'"
            :placeholder="
              $t('FIRST_NAME_PLACEHOLDER')
            "
            :cy-selector="'name-input'"
            :input-icon-error=" require('../../../assets/icons/icn_error.svg') "
            :api-error-text="''"
            class="form-element"
          />
          <InputField
            :field-title="$t('LAST_NAME')"
            :field-name="'lastName'"
            :rules="'required'"
            :type="'text'"
            :placeholder="
              $t('LAST_NAME_PLACEHOLDER')
            "
            :cy-selector="'name-input'"
            :input-icon-error=" require('../../../assets/icons/icn_error.svg') "
            :api-error-text="''"
            class="form-element"
          />

        </div>

        <!-- Email -->
        <InputField
          :field-title="$t('EMAIL_ADDRESS')"
          :field-name="'email'"
          :rules="'required'"
          :type="'email'"
          :placeholder="$t('TEACHER_OVERVIEW.CREATE_TEACHER.EMAIL_PLACEHOLDER')"
          :cy-selector="'name-input'"
          :input-icon-error=" require('../../../assets/icons/icn_error.svg') "
          :api-error-text="apiErrorTextEmail"
          class="form-element"
        />

        <!-- School -->
        <Search
          :search-title="$t('SEARCH.INPUT_TITLE')"
          :placeholder="selectedSchool.name ? selectedSchool.name : $t('SEARCH.PLACEHOLDER')"
          :search-id="'search2'"
          :value="schoolSearchValue"
          :manage-search="manageSearch"
          :clear-search="clearSearch"
          :search-results="searchResults"
          :placeholder-class="selectedSchool.name ? 'selected-placeholder' : ''"

          class="search-menu"
        >
          <!-- Result indicator -->
          <template #content-below="searchedTerm">
            <p v-if="searchedTerm && searchResults && !selectedSchool.name" class="result-indicator">
              {{ searchResults.length }} {{ $t('RESULTS') }}
            </p>
          </template>
        </Search>

        <!-- Search results -->
        <SearchResults
          :search-results="searchResults"
          :on-item-click="onItemClick"
        />
      </template>

      <template #button-submit>
        <button
          type="submit"
          class="pink-button submit-button"
        >
          {{ $t('TEACHER_OVERVIEW.CREATE_TEACHER.CREATE_TEACHER') }}
        </button>
      </template>
    </FormWrapper>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import BackButton from '@/components/elements/BackButton'
import InputField from '@/components/yo-form/InputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import { ROUTE_NAMES_HELPDESK } from '@/router/modules/helpdesk'
import Search from '@/components/elements/selectSchools/Search'
import SearchResults from '@/components/elements/selectSchools/SearchResults'
import { useStore } from 'vuex'
import { SEARCH_SCHOOLS } from '@/store/modules/auth/actions'
import { RESET_SEARCHED_SCHOOLS } from '@/store/modules/auth/mutations'
import { CREATE_TEACHER } from '@/store/modules/helpdesk/actions'
import { computed } from '@vue/reactivity'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'TeacherCreate',
  components: {
    PageTitle,
    BackButton,
    InputField,
    FormWrapper,
    Search,
    SearchResults
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const searchResults = computed(() => store.getters.getSearchedSchools.schools || [])
    const apiErrorTextEmail = ref('')


    function validateData(payload) {
      apiErrorTextEmail.value = ''
      payload.schoolId = selectedSchool.value.id
      store.dispatch(CREATE_TEACHER, payload).then(result => {
        if (result.status === 204) {
          router.push({ name: ROUTE_NAMES_HELPDESK.TEACHERS_OVERVIEW })
        } else if (result.status === 409) {
          apiErrorTextEmail.value = 'Dit e-mailadres is al in gebruik.'
        }
      })
      // TODO: implement submission form.
    }
    /** Search **/
    function manageSearch(data) {
      schoolSearchValue.value = data
      if (!data) {
        clearSearch()
      } else {
        const payload = {
          searchQuery: data,
          page: 1
        }
        searchSchool(payload)
      }
    }

    const schoolSearchValue = ref('')

    const selectedSchool = ref({})

    function onItemClick(item) {
      selectedSchool.value = item
      schoolSearchValue.value = item.name
      clearSearch()
    }

    function clearSearch() {
      schoolSearchValue.value = ''
      //schoolSearchValue.value = ''
      // Reset the stored searched schools.
      store.commit(RESET_SEARCHED_SCHOOLS, undefined)
    }

    function searchSchool(payload) {
      return store.dispatch(SEARCH_SCHOOLS, payload)
    }

    return {
      ROUTE_NAMES_HELPDESK,
      validateData,
      useStore,
      manageSearch,
      clearSearch,
      searchSchool,
      searchResults,
      selectedSchool,
      onItemClick,
      schoolSearchValue,
      apiErrorTextEmail
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/base.variables';
@import '~@/assets/css/base.mixins';

.search-menu {
  margin-bottom: 1.75rem;
}
:deep {
  .result-indicator {
    margin-top: rem(10);
  }
}

.name-columns {
  gap: rem(20);
}


</style>
